<template>
	<section class="container">
		<div>
			<h1>Opret medarbejder</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row">
			<b-form @submit.prevent="onSubmit">
				<i>Det er meget vigtigt, at du udfylder medarbejderens fulde navn - til brug for legitimering jf. hvidvaskloven. </i>
				<UserInfo v-model="employeeInfo" @lookupCpr="lookupCpr"></UserInfo>
				<div class="col-12">
					<hr />
				</div>

				<UserEmployeePolicy v-model="employeePolicy" :groups="groups"></UserEmployeePolicy>
				<div class="row mb-5 mt-3">
					<div class="col-12">
						<BaseActionButton class="btn-add" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
							{{ $t('global.create') }}
						</BaseActionButton>
					</div>
				</div>
			</b-form>
		</div>
	</section>
</template>

<script>
import { superModel } from '@/models/superModel'
import UserInfo from '@/modules/general/company/components/UserInfo.vue'
import UserEmployeePolicy from '@/modules/general/company/components/UserEmployeePolicy.vue'

export default {
	name: 'CompanyEmployeeAdd',
	components: {
		UserInfo,
		UserEmployeePolicy,
	},
	async mounted() {
		console.log('Called')
		this.employeeInfo = superModel('personInfo', {})
		var employeePolicy = superModel('employeePolicy', {})
		this.employeePolicy = employeePolicy
		console.log(this.employeePolicy)
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			clicked: false,
			disabled: false,
			employeeInfo: {},
			employeePolicy: {},
		}
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			this.employeePolicy.companyAdvisors = this.company.companyAdvisors
			var data = {
				employeeInfo: this.employeeInfo,
				employeePolicy: this.employeePolicy,
			}

			try {
				var result = await this.$store.dispatch('companyVuex/registerEmployee', {
					data: data,
					id: this.companyId,
				})
				var uid = result.uid
				var employeePolicyId = result?.policy?.id
				this.toast('Success', 'En medarbejder nu oprettet', true)
				this.$router.push({
					name: 'CompanyEmployeeInfo',
					params: { companyId: this.companyId, employeeId: uid, policyId: employeePolicyId },
				})
			} catch (err) {
				console.log(err)
				this.toast('Error', 'Noget gik galt: ' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
		async lookupCpr(cpr) {
			console.log(cpr)
			var user = await this.$store.dispatch('companyVuex/getEmployeeByCpr', cpr)
			if (user) {
				this.employeeInfo = user
			}
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		groups() {
			return this.company.groups
		},
	},
}
</script>

<style></style>
